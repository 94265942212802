<template>
  <step-lesson-layout class="m3-p2-lesson">
    <template v-slot:head>
      <h1 class="lesson-title">{{ $t('module3.part2.lesson1.title') }}</h1>
      <p class="lesson-title lesson-sub-title">{{ $t('module3.part2.lesson1.subTitle') }}</p>
      <div class="title-annotation">
        <p>{{ $t('module3.part2.lesson1.intro') }}</p>
        <p v-html="$t('module3.part2.lesson1.intro2')"></p>
      </div>
    </template>
    <template v-slot:content>
      <div class="grid-wrapper">
        <!-- BLOCK 1 -->
        <div class="lesson-block">
          <p class="title">{{ $t('module3.part2.lesson1.block1.title') }}</p>
          <div class="intro-block">
            <div class="image-wrapper">
              <img :src="require('@/assets/module3/part2/illu-parcours-accompagne.svg')" :alt="$t('global.imageAlts.illuParcoursAccompagne')">
            </div>
            <div class="intro">
              <p v-html="$t('module3.part2.lesson1.block1.intro')"></p>
            </div>
          </div>
          <div class="logement-block">
            <p class="block-title">{{ $t('module3.part2.lesson1.logementLabel') }}</p>
            <div class="classe-icon" v-html="require('@/assets/module3/part2/classescdefg.svg?inline')"></div>
          </div>
          <div class="conditions-block">
            <p class="block-title">{{ $t('module3.part2.lesson1.conditionLabel') }}</p>
            <div class="conditions">
              <div class="condition">
                <div class="img-wrapper">
                  <img :src="require('@/assets/module3/part2/loupe.svg')" :alt="$t('global.imageAlts.loupeIcon')">
                </div>
                <p>{{ $t('module3.part2.lesson1.block1.conditions.eval') }}</p>
              </div>
              <div class="condition">
                <div class="img-wrapper">
                  <img :src="require('@/assets/module3/part2/perso.svg')" :alt="$t('global.imageAlts.quiIllu')">
                </div>
                <p>{{ $t('module3.part2.lesson1.block1.conditions.accomp') }}</p>
              </div>
              <div class="condition">
                <div class="img-wrapper">
                  <img :src="require('@/assets/module3/part2/ico-gain-classe.svg')" :alt="$t('global.imageAlts.sautClasseIllu')">
                </div>
                <p>{{ $t('module3.part2.lesson1.block1.conditions.sautClasse') }}</p>
              </div>
            </div>
          </div>
          <div class="montant-block">
            <p class="block-title">{{ $t('module3.part2.lesson1.montantLabel') }}</p>
            <div class="calcul">
              <div class="img-wrapper">
                <img :src="require('@/assets/money-ico.svg')">
              </div>
              <p v-html="$t('module3.part2.lesson1.block1.montantInfo')"></p>
            </div>
            <p v-html="$t('module3.part2.lesson1.block1.montantNiveau')"></p>
            <div class="montants">
              <div class="montant">
                <div class="montant-wrapper bleu">
                  <div class="mpr">
                    <img :src="require('@/assets/module3/part2/logo-mpr-bleu.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                    <p>{{ $t('module3.part2.lesson1.mprMenages.tresModeste') }}</p>
                  </div>
                  <div class="value">
                    <p v-html="$t('module3.part2.lesson1.block1.montants.tresModeste.montant')"></p>
                  </div>
                </div>
              </div>
              <div class="montant">
                <div class="montant-wrapper jaune">
                  <div class="mpr">
                    <img :src="require('@/assets/module3/part2/logo-mpr-jaune.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                    <p>{{ $t('module3.part2.lesson1.mprMenages.modeste') }}</p>
                  </div>
                  <div class="value">
                    <p v-html="$t('module3.part2.lesson1.block1.montants.modeste.montant')"></p>
                  </div>
                </div>
              </div>
              <div class="montant">
                <div class="montant-wrapper violet">
                  <div class="mpr">
                    <img :src="require('@/assets/module3/part2/logo-mpr-violet.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                    <p>{{ $t('module3.part2.lesson1.mprMenages.inter') }}</p>
                  </div>
                  <div class="value">
                    <p v-html="$t('module3.part2.lesson1.block1.montants.inter.montant')"></p>
                  </div>
                </div>
              </div>
              <div class="montant">
                <div class="montant-wrapper rose">
                  <div class="mpr">
                    <img :src="require('@/assets/module3/part2/logo-mpr-rose.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                    <p>{{ $t('module3.part2.lesson1.mprMenages.aise') }}</p>
                  </div>
                  <div class="value">
                    <p v-html="$t('module3.part2.lesson1.block1.montants.aise.montant')"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="montant-block">
            <p v-html="$t('module3.part2.lesson1.block1.montantPlafond')"></p>
            <br>
            <app-modal theme="training">
              <template v-slot:modal-button>
                <app-button-layout class="modal-button" color="alternate-secondary" :small="true">{{ $t('module3.part2.lesson1.block1.modalButton') }}</app-button-layout>
              </template>
              <template v-slot:content>
                <div class="modal-wrapper">
                  <img :src="require('@/assets/modal-montants-plafonds.png')" :alt="$t('global.imageAlts.montantsMpr')">
                </div>
              </template>
            </app-modal>
          </div>
        </div>

        <!-- BLOCK 2 -->
        <div>
          <div class="lesson-block">
            <p class="title">{{ $t('module3.part2.lesson1.block2.title') }}</p>
            <div class="intro-block">
              <div class="image-wrapper">
                <img :src="require('@/assets/module3/part2/illu-par-geste.svg')" :alt="$t('global.imageAlts.illuParcoursAccompagne')">
              </div>
              <div class="intro">
                <p v-html="$t('module3.part2.lesson1.block2.intro')"></p>
              </div>
            </div>
            <div class="logement-block">
              <p class="block-title">{{ $t('module3.part2.lesson1.logementLabel') }}</p>
              <div class="classe-icon" v-html="require('@/assets/module3/part2/classes.svg?inline')"></div>
              <app-icon-block type="warning" :content="$t('module3.part2.lesson1.block2.warning')"></app-icon-block>
            </div>
            <div class="conditions-block">
              <p class="block-title">{{ $t('module3.part2.lesson1.conditionLabel') }}</p>
              <div class="conditions">
                <div class="condition">
                  <div class="img-wrapper">
                    <img :src="require('@/assets/module3/part2/loupe.svg')" :alt="$t('global.imageAlts.loupeIcon')">
                  </div>
                  <div>
                    <p>{{ $t('module3.part2.lesson1.block2.condition') }}</p>
                    <a class="link-internal" href="https://s3.fr-par.scw.cloud/oscar/tools/ressources-quellesAidesquelsTravaux-v4.pdf" target="_blank" rel="noopener">{{ $t('module3.part2.lesson1.block2.link') }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="menages-block">
              <p class="block-title">{{ $t('module3.part2.lesson1.menageLabel') }}</p>
              <mpr-montant-by-niveau-revenu :show-value="false" :niveaux-revenus="niveauxRevenus" />
            </div>
            <div class="montant-block">
              <p class="block-title">{{ $t('module3.part2.lesson1.montantLabel') }}</p>
              <div class="calcul">
                <div class="img-wrapper">
                  <img :src="require('@/assets/money-ico.svg')">
                </div>
                <p v-html="$t('module3.part2.lesson1.block2.montantForfait')"></p>
              </div>
            </div>
            <div class="modal-button-wrapper">
              <montant-mpr-modal :src-img="require('@/assets/modal-montants-forfaitaires.png')" :button-label="$t('module3.part2.lesson1.block2.modalButton')" />
            </div>
          </div>
        </div>
      </div>

      <!-- BLOCK 4 -->
      <div class="app-text-block">
        <p class="title">{{ $t('module3.part2.lesson1.block4.title') }}</p>
        <div class="check-group">
          <app-icon-block type="check" :content="$t('module3.part2.lesson1.block4.check1')"></app-icon-block>
          <app-icon-block type="warning" :content="$t('module3.part2.lesson1.block4.warning1')"></app-icon-block>
        </div>
        <div class="check-group">
          <app-icon-block type="check" :content="$t('module3.part2.lesson1.block4.check2')"></app-icon-block>
        </div>
        <div class="check-group">
          <app-icon-block type="check" :content="$t('module3.part2.lesson1.block4.check3')"></app-icon-block>
          <app-icon-block type="info" :content="$t('module3.part2.lesson1.block4.info')"></app-icon-block>
        </div>
        <div class="check-group">
          <app-icon-block type="check" :content="$t('module3.part2.lesson1.block4.check4')"></app-icon-block>
        </div>
        <div class="check-group">
          <app-icon-block type="check" :content="$t('module3.part2.lesson1.block4.check5')"></app-icon-block>
        </div>
      </div>

      <div class="buttons-wrapper">
        <app-button-layout class="resume-button" @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppModal from '@/components/layout/AppModal'
import AppIconBlock from '@/components/layout/AppIconBlock'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import MontantMprModal from '@/components/training/MontantMprModal.vue'
import MprMontantByNiveauRevenu from '@/components/training/MprNiveauxRevenus.vue'

export default {
  name: 'Module3Part2Lesson1',
  components: { MprMontantByNiveauRevenu, MontantMprModal, AppButtonLayout, AppIconBlock, AppModal, StepLessonLayout },
  emits: ['next-tab'],
  data () {
    return {
      niveauxRevenus: [
        {
          name: 'tresModeste',
          label: this.$t('training.mprMenages.tresModeste'),
          value: '90%'
        },
        {
          name: 'modeste',
          label: this.$t('training.mprMenages.modeste'),
          value: '75%'
        },
        {
          name: 'intermediaire',
          label: this.$t('training.mprMenages.intermediaire'),
          value: '60%'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.step-lesson-layout.m3-p2-lesson .step-lesson-layout-content {
  margin-top: 0;
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/module3Lesson";

.condition-block-2 {
  padding-top: $space-sm;
  border-top: 1px solid $c-text-dark;
}

.block-title {
  color: $c-text-dark;
  font-size: $fz-s;
  font-weight: $fw-l;
  text-transform: uppercase;
  margin-bottom: $space-sm;
}

.intro-block {
  margin-top: $space-l;
  display: flex;
  align-items: center;
  text-wrap: balance;
  .image-wrapper {
    padding: $space-s;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: $radius;
    background-color: #fff;
    margin-right: $space-s;
    img {
      max-width: 100%;
    }
  }
}

.conditions-block {
  margin-top: $space-m;
  font-size: $fz-s;
}

.logement-block {
  margin-top: $space-m;
  .app-icon-block {
    margin: $space-s $space-s;
    align-items: center;
  }
}

.menages-block {
  margin-top: $space-m;
}

.modal-button-wrapper {
  display: flex;
  justify-content: center;
  margin: $space-s auto;
}

.montant-block {
  margin: $space-sm 0;
  .montants {
    margin-top: $space-sm;
  }
  .calcul {
    display: flex;
    gap: 0 $space-s;
    margin-bottom: $space-sm
  }
  .montants-simple {
    display: flex;
    justify-content: space-evenly;
    .mpr + .mpr {
      border-left: 1px solid rgba(#0E6CAA, 0.3);
    }
    .mpr {
      padding: 0 $space-s;
      flex: 1 0 0;
      text-align: center;
      font-weight: $fw-m;
    }
  }
}

.illu-bonus {
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.app-text-block {
  padding: 0 $space-s;
  .title {
    margin-bottom: $space-l;
  }
  .check-group + .check-group {
    margin-top: $space-s;
    padding-top: $space-s;
    border-top: 1px solid $c-border;
  }
  .check-group {
    .app-icon-block + .app-icon-block {
      margin-top: $space-s;
    }
  }
}

.grid-wrapper {
  display: grid;
  gap: $space-sm;
  margin-top: $space-sm;
}

.link-internal {
  font-size: $fz-s
}

.classe-icon {
  text-align: center;
}

@media (min-width: $bp-tablet) {
  .lesson-block + .lesson-block {
    margin-top: 0;
  }
  .app-text-block {
    padding: 0 $space-l;
  }
  .grid-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fit, 1fr);
    .block-2 {
      grid-column-start: 2;
    }
    .block-3 {
      grid-row-start: 1;
    }
  }
}

.paragraphe {
  margin-top: $space-m;
}
</style>
